import { Middleware } from 'redux';
import { fetchWrapper } from 'src/middlewares/updateResponseMiddleware';
import {
  remove,
  removeinvite,
  removeZoomedOffer,
  setFilters,
  removePosterFilter,
  setPosterFilters,
  setSignUpCooldownExpiry,
  toggleNotifications,
  setZoomedOffer,
  PostedOfferType,
  updateSingleSignup
} from 'src/features/offerSlice';
import { update as updateUser, DeleteMessages } from 'src/features/userSlice';
import {
  LFSType,
  LFGType,
  Offer,
  OfferExpiryTimes,
  offerObjectType
} from 'src/models/offer';
import { User } from 'src/models/user';

const offerMiddleware: Middleware = (storeAPI) => {
  return (next) => async (action) => {
    switch (action.type) {
      case 'offer/notification':
        storeAPI.dispatch(toggleNotifications());
        break;
      case 'offer/signup/fastsignup':
        try {
          await fetchWrapper(
            `${process.env.REACT_APP_API_URL}/keys/signup/fast`,
            {
              credentials: 'include',
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(action.payload)
            },
            action,
            storeAPI,
            'Successfully signed up',
            'Failed to sign up'
          );
        } catch (error) {
          console.error('Failed to fetch offers', error);
        }
        break;
      case 'offer/filters/update':
        storeAPI.dispatch(setFilters(action.payload));
        break;
      case 'offer/posterfilters/update':
        storeAPI.dispatch(
          setPosterFilters({
            _id: action.payload._id,
            filters: action.payload.filters
          })
        );
        break;
      case 'offer/signup/setCooldownExpiry':
        storeAPI.dispatch(setSignUpCooldownExpiry(action.payload));
        break;
      case 'offer/signup/update':
        storeAPI.dispatch(updateSingleSignup(action.payload));
        break;
      case 'offer/cleanup':
        const lastUpdate = storeAPI.getState().averageStats?.LastUpdate;
        if (!lastUpdate || lastUpdate < Date.now() - 30 * 60 * 1000) {
          storeAPI.dispatch({
            type: 'socket/Message/send',
            payload: { type: 'average_stats', silent: true }
          });
        }
        let remainingOffersIDs = [];
        const user: User = storeAPI.getState().user.user;
        const {
          activeOffers,
          postedOffers,
          zoomedOffers,
          posterfilters
        }: {
          activeOffers: { [key: string]: offerObjectType };
          postedOffers: { [key: string]: PostedOfferType };
          zoomedOffers: {
            [key: string]: {
              _id: string;
              offer: offerObjectType;
              postedOffer: PostedOfferType;
            };
          };
          posterfilters: { [key: string]: { [key: string]: number } };
        } = storeAPI.getState().offer;
        const now = Date.now() / 1000;
        const oneWeekAgo = now - 7 * 24 * 60 * 60;
        for (const [key, offer] of Object.entries(activeOffers)) {
          if (offer.Status === 'Pending') continue;
          const comparePoint =
            'ScheduledStartTime' in offer && offer.ScheduledStartTime
              ? offer.ScheduledStartTime
              : offer.StartTime;
          if (
            offer.Status !== 'Completed' &&
            comparePoint <
              now - OfferExpiryTimes[offer.OfferType ?? 'MythicPlus'][1]
          ) {
            if (postedOffers) {
              storeAPI.dispatch(
                setZoomedOffer({
                  _id: key,
                  offer: { ...offer },
                  postedOffer: { ...postedOffers[key] }
                })
              );
              if (!user.RecentRuns[key]) {
                storeAPI.dispatch(
                  updateUser({
                    RecentRuns: {
                      ...user.RecentRuns,
                      [key]: { PosterID: offer.PosterID }
                    }
                  })
                );
              }
            }
            storeAPI.dispatch(remove(key));
          } else {
            remainingOffersIDs.push(key);
          }
        }
        for (const [key, zoomedOffer] of Object.entries(zoomedOffers)) {
          const comparePoint =
            'ScheduledStartTime' in zoomedOffer.offer &&
            zoomedOffer.offer.ScheduledStartTime
              ? zoomedOffer.offer.ScheduledStartTime
              : zoomedOffer.offer.Status === 'Pending'
              ? zoomedOffer.offer.UploadTime
              : zoomedOffer.offer.StartTime;
          if (
            comparePoint <
            now -
              OfferExpiryTimes[zoomedOffer.offer.OfferType ?? 'MythicPlus'][1]
          ) {
            storeAPI.dispatch(removeZoomedOffer(key));
          } else {
            remainingOffersIDs.push(zoomedOffer._id);
          }
        }
        for (const key of Object.keys(posterfilters)) {
          if (!postedOffers[key]) {
            storeAPI.dispatch(removePosterFilter(key));
          } else {
            remainingOffersIDs.push(key);
          }
        }
        for (const GroupID of user.Messages?.Group
          ? Object.keys(user.Messages.Group)
          : []) {
          if (!remainingOffersIDs.includes(GroupID)) {
            storeAPI.dispatch(DeleteMessages({ type: 'Group', id: GroupID }));
          }
        }
        break;
      case 'offer/zoomedoffer/set':
        storeAPI.dispatch(setZoomedOffer(action.payload));
        break;
      case 'offer/zoomedoffer/remove':
        storeAPI.dispatch(removeZoomedOffer(action.payload));
        break;
      case 'removeinvite':
        storeAPI.dispatch(removeinvite());
        break;
      default:
        break;
    }

    return next(action);
  };
};

export default offerMiddleware;
